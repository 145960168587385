import React, { useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import logo from './logo.svg';
import './App.css';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import schema from './schema.json';
import uischema from './uischema.json'
import { uischema_js } from './uischema'
import PersonalInformation from './PersonalInformation';
import Banner from './Banner';

function App() {

  const initialData = {
    name: 'Send email to Adrian',
    description: 'Confirm if you have passed the subject\nHereby ...',
    done: true,
    recurrence: 'Daily',
    rating: 3,
  };

  const [data, setData] = useState<any>(initialData);
  return (
    <>
      <Banner />
      <PersonalInformation />
      <button>Next</button>
    </>
  );
}

export default App;
