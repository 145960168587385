import React, { useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import logo from './logo.svg';
import './App.css';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import schema from './schema.json';
import uischema from './uischema.json'
import { uischema_js } from './uischema'

interface Props {

}

const PersonalInformation = (props: Props) => {

  const initialData = {
    name: 'Send email to Adrian',
    description: 'Confirm if you have passed the subject\nHereby ...',
    done: true,
    recurrence: 'Daily',
    rating: 3,
  };

  const [data, setData] = useState<any>(initialData);
  return (
    <div className='App' style={{
      margin: 'auto',
      padding: '1rem',
      maxWidth: "800px"
    }}>
      <JsonForms
        schema={schema}
        // uischema={uischema}
        uischema={uischema_js("Horizontal")}
        data={data}
        renderers={materialRenderers}
        cells={materialCells}
        onChange={({ data }) => setData(data)}
      />
    </div>
  );
}

export default PersonalInformation
