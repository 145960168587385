export const uischema_js = (orient) => {
  return ({
    "type": "VerticalLayout",
    "elements": [
      {
        "type": "Group",
        "label": "Personal Information",
        "elements": [
          {
            "type": `${orient}Layout`,
            "elements": [
              {
                "type": "Control",
                "scope": "#/properties/first_name"
              },
              {
                "type": "Control",
                "scope": "#/properties/surname"
              }
            ]

          },
          {
            "type": "Control",
            "scope": "#/properties/dob"
          }
        ]
      },
      {
        "type": "Group",
        "label": "Social Media",
        "elements": [
          {
            "type": "Control",
            "scope": "#/properties/linkedin"
          },
          {
            "type": "Control",
            "scope": "#/properties/facebook"
          },
          {
            "type": "Control",
            "scope": "#/properties/instagram"
          },
        ]
      },
      {
        "type": "Group",
        "label": "Career Goals",
        "elements": [
          {
            "type": "Control",
            "scope": "#/properties/career_goals",
            "options": {
              "multi": true
            }
          },
        ]
      }
    ]
  })
}
