import React from 'react'

interface Props {

}

const Banner = (props: Props) => {
  return (
    <div style={{ background: "lightgrey", minHeight: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <h1>Radial Targeted Job and Task Matching</h1>
    </div>
  )
}

export default Banner
